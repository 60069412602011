import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import clsx from 'clsx';
import { getIssueNodes, getRecentIssueNumberSet, formatPublishDate } from '../utils';
import { SOURCE_INDEX } from '../constants';
import PageHeader from '../components/page-header';
import PageFooter from '../components/page-footer';
import PageLayout from '../components/page-layout';
import TableOfContents from '../components/toc';
import './index.scss';

const IndexPage = ({ data }) => {
  const issues = getIssueNodes(data);

  const [expandState, setExpandState] = React.useState(
    getRecentIssueNumberSet(issues)
  );

  const expand = (n) => {
    const newState = new Set(expandState);
    newState.add(n);
    setExpandState(newState);
  };

  const collapse = (n) => {
    const newState = new Set(expandState);
    newState.delete(n);
    setExpandState(newState);
  };

  const toggleExpandState = (n) => {
    if (expandState.has(n)) {
      collapse(n);
    } else {
      expand(n);
    }
  };

  return (
    <PageLayout className="IndexPage">
      <Helmet>
        <title>Firstness Journal</title>
      </Helmet>
      <PageHeader />
      <div className="features" />
      <main id="read">
        <h1>Issues</h1>
        {issues.filter((issue) => issue.articles.length > 0).map((issue, index) => {
          const {
            fields: {
              slug
            },
            frontmatter: {
              issue_number: issueNumber,
              publish_date,
            },
            articles
          } = issue;
          const isExpanded = expandState.has(issueNumber);
          const headerProps = {
            role: 'button',
            tabIndex: index,
            onClick: () => toggleExpandState(issueNumber),
            onKeyDown: (e) => {
              switch (e.keyCode) {
                case 13:
                  e.preventDefault();
                  toggleExpandState(issueNumber);
                  break;
                case 9:
                  expand(issueNumber);
                  break;
                default:
                  break;
              }
            }
          };
          return (
            <article
              key={index}
              className={
                clsx(
                  "issue",
                  {
                    "issue--expanded": isExpanded
                  }
                )
              }
            >
              <div>
                <header {...headerProps}>
                  <div className="description">
                    <Link className="link" title={`Read Issue #${issueNumber}`} to={`/issues/${slug}/${articles[0].fields.slug}`} state={{ source: SOURCE_INDEX }}>
                      <span className="number">#{issueNumber}{' '}</span>
                      <span className="publish-date">{formatPublishDate(publish_date)}{' '}</span>
                    </Link>
                  </div>
                  <div className="actions">
                    <a download href={`/media/issue-${slug}.pdf`} className="download-pdf" title="Download PDF" onClick={e => e.stopPropagation()}>
                      <span className="label">Download PDF</span>
                      <span className="label-compact">PDF</span>
                    </a>
                  </div>
                </header>
                <div className="contents">
                  <TableOfContents issue={issue} displayOptions={{ author: true }} />
                </div>
              </div>
            </article>
          );
        })}
      </main>
      <div id="subscribe" className="newsletter" />
      <PageFooter />
    </PageLayout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    issues: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: { eq: "issue" }
        }
      },
      sort: {
        order: DESC,
        fields: [
          frontmatter___issue_number
        ]
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            issue_number
            publish_date
          }
        }
      }
    }
    articles: allMarkdownRemark(
      limit: 1000,
      filter: {
        frontmatter: {
          type: { eq: "article" }
        }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            issue
            sort_order
            title
            author
          }
        }
      }
    }
  }
`;
